function initializeRecording() {
    if (window.recordingInitialized) {
        return;
    }

    var recorder;
    // var downloadButton = document.getElementById('btn-download');
    var screenStream;
    var webcamStream;

    function invokeGetDisplayMedia(success, error) {
        var displaymediastreamconstraints = {
            video: {
                displaySurface: 'monitor',
                logicalSurface: true,
                cursor: 'always'
            }
        };

        displaymediastreamconstraints = {
            video: true
        };

        if (navigator.mediaDevices.getDisplayMedia) {
            navigator.mediaDevices.getDisplayMedia(displaymediastreamconstraints).then(success).catch(error);
        } else {
            navigator.getDisplayMedia(displaymediastreamconstraints).then(success).catch(error);
        }
    }

    function captureScreen(callback) {
        invokeGetDisplayMedia(function (screen) {
            
            screenStream = screen;
            addStreamStopListener(screen, function () {
                
                if (window.stopCallback) {
                    window.stopCallback();
                }
            });
            callback(screen);
        }, function (error) {
            console.error('Error capturing screen:', error);
            alert('Unable to capture your screen. Please check console logs.\n' + error);
        });
    }

    function captureCamera(cb) {
        // navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(cb);
        navigator.mediaDevices.getUserMedia({ audio: true, video: { facingMode: 'user' } })
            .then(stream => {
                webcamStream = stream;
                cb(stream);
            })
            .catch(error => {
                console.error("Error accessing webcam:", error);
            });
    }

    function keepStreamActive(stream) {
        var video = document.createElement('video');
        video.muted = true;
        video.srcObject = stream;
        video.style.display = 'none';
        (document.body || document.documentElement).appendChild(video);
    }


    function startRecording() {
        // Check if recorder is already recording
        if (recorder && recorder.getState() === 'recording') {
            return;
        }
    
        var existingVideo = document.querySelector('video');
        if (!existingVideo) {
            existingVideo = document.createElement('video');
            existingVideo.style.display = 'none'; // Hide the video initially
            (document.body || document.documentElement).appendChild(existingVideo);
        }
    
        // downloadButton.disabled = true; // Disable download button when recording starts
        captureScreen(function (screen) {
            keepStreamActive(screen);
            captureCamera(function (camera) {
                keepStreamActive(camera); 
                screen.width = window.screen.width;
                screen.height = window.screen.height;
                screen.fullcanvas = true;
    
                camera.width = 320;
                camera.height = 240;
                camera.top = screen.height - camera.height;
                camera.left = screen.width - camera.width;
    
                recorder = RecordRTC([screen, camera], {
                    type: 'video',
                    mimeType: 'video/mp4',
                    previewStream: function (s) {
                        existingVideo.muted = true;
                        existingVideo.srcObject = s;
                        existingVideo.style.display = 'block'; // Show the video
                    }
                });
                recorder.startRecording();
            });
        });
    }
    

    
    function stopRecording() {
        if (recorder) {
            if (!window.stopCallback) {
                window.stopCallback = function () {
                    // Mute the video
                    var video = document.querySelector('video');
                    if (video) {
                        video.muted = true;
                    }
    
                    recorder.stopRecording(function () {
                        var blob = recorder.getBlob();
                        blob.type = "video/mp4"
    
                        // Hide the video element
                        var video = document.querySelector('video');
                        if (video) {
                            video.style.display = 'none';
                        }
    
                        // Clear the video source
                        if (video) {
                            video.srcObject = null;
                            video.src = URL.createObjectURL(blob);
                            video.muted = false;
                        }
    
                        // Stop the camera stream
                        if (screenStream) {
                            var tracks = screenStream.getTracks();
                            tracks.forEach(function (track) {
                                track.stop();
                            });
                            screenStream = null;
                        }
                        if (webcamStream) {
                            webcamStream.getTracks().forEach(track => track.stop());
                            webcamStream = null;
                        }
    
                        // Upload the recorded video to Filestack
                        uploadToFilestack(blob);
                    });
                };
            }
    
            // Simulate a click on the stop sharing button
            var stopSharingButton = document.querySelector('[aria-label="Stop sharing"]');
            if (stopSharingButton) {
                stopSharingButton.click();
            } else {
                // If the stop sharing button is not found, invoke the stop callback directly
                window.stopCallback();
            }
        }
    }
    
    

    function uploadToFilestack(blob) {
        const videoUrl = $("#response_video_url");
        const video = $("#video");
        // const client = filestack.init(process.env['API_KEY']);
        const client = filestack.init(process.env['FILESTACK_API_KEY']);
        // const client = filestack.init(process.env.FILESTACK_API_KEY);
    
        client.upload(blob)
            .then((uploadRes) => {
                const videoTag = `<video controls="controls" src="${uploadRes.url}" width="400"></video>`;
                videoUrl.val(uploadRes.url);
                video.html(videoTag);
            })
            .catch((error) => {
                // Handle upload error
                console.error('Error uploading file to Filestack:', error);
            });
    }
    

    function downloadRecording() { 
        if (recorder) {
            recorder.save('recorded-video.mp4');
        }
    }

    function deleteVideo() {
        var video = document.querySelector('video');
        if (video) {
            video.parentNode.removeChild(video);
        }
    }
    
    
    function addStreamStopListener(stream, callback) {
        stream.addEventListener('ended', function () {
            callback();
            callback = function () {};
        }, false);
        stream.addEventListener('inactive', function () {
            callback();
            callback = function () {};
        }, false);
        stream.getTracks().forEach(function (track) {
            track.addEventListener('ended', function () {
                callback();
                callback = function () {};
            }, false);
            track.addEventListener('inactive', function () {
                callback();
                callback = function () {};
            }, false);
        });
    }

    document.addEventListener('btn-start-recording', function () {
        startRecording();
    });

    document.addEventListener('btn-stop-recording', function () {
        stopRecording();
    });

    document.addEventListener('btn-download', function () {
        downloadRecording();
    });

    document.addEventListener('btn-delete', function () {
        deleteVideo();
    });

    // Mark that event listeners are initialized
    window.recordingInitialized = true;

}


document.addEventListener('turbolinks:load', function (event) {
        initializeRecording();
});